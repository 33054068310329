@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@700&display=swap");

.ff {
  padding-left: 1rem;
  font-family: "Poppins", sans-serif;
  color: green;
}
.ss {
  padding-left: 1rem;
  font-family: "Poppins", sans-serif;
  color: rgb(0, 78, 29);
}
.fruit-section {
  width: calc(100% - 8rem);
  margin: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: rgb(106, 106, 106);
}

.marked {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  border-radius: 1000px;
  background-color: #ddd;
  z-index: 99;
}
.iammarked {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 1000px;
  background-color: green;
}
.section-title {
  font-family: "headerFont";
  margin-bottom: 3rem;
  font-size: 1.8rem;
  width: auto;
  padding-top: 4rem;
}
/* .section-title::after {
  content: "";
  display: block;
  height: 3px;
  width: 8rem;
  margin: auto;
  border-radius: 1000px;
  background-color: green;
} */
.single-fruit-section {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 1rem;
  /* flex-wrap: wrap; */
  /* width: 100vw; */
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
.single-fruit-container {
  flex-wrap: wrap;
  display: "flex";
  flex-direction: column;
  margin: 2rem;
  justify-content: center;
  width: auto;
  align-items: center;
}
.fruit-img {
  width: calc(100vw / 3 - 20rem / 3);
  background-color: rgb(222, 222, 222);
  height: 15vw;
  border-radius: 1.2rem;
}
.fruit-text {
  text-align: center;
  display: flex;
  /* justify-content: center; */
  padding-left: 1.4rem;
  width: 100%;
  padding-top: 0.5rem;
  font-family: "Open sans", sans-serif;
}
.mango {
  color: orangered;
}
.avocado {
  color: rgb(3, 172, 3);
}
.pineapple {
  color: rgb(196, 131, 0);
}
.banana {
  color: rgb(183, 183, 2);
}

.fruit-nav {
  min-width: 80vw;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.7rem 1rem;

  background-color: rgb(247, 255, 247);
  border-radius: 1000px;
}

.fruit-nav ul {
  display: flex;
  justify-content: space-around;
  flex-grow: 1rem;
}
.fruit-nav ul li {
  list-style: none;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border: 1px solid rgb(117, 137, 120);
  color: rgb(57, 64, 60);
  cursor: pointer;
  border-radius: 1000px;
}

.ssimg {
  /* max-height: 100%; */
  position: absolute;
  /* : 100%; */
  top: 0;
  left: 0;
}

.card {
  /* cursor: pointer; */
  position: relative;
  background: #ddd;
  width: calc(25vw - 7.5rem);
  height: 30vw;
  border-radius: 30px;
  padding: 2rem;
  color: #aaa;
  /* box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2); */
  overflow: hidden;
}
.card50 {
  width: calc(50vw - 10rem);
  height: 20vw;
}
.card100 {
  width: calc(100vw - 15rem);
  height: 20vw;
}
.card__image-container {
  margin: -2rem -2rem 5rem -2rem;
}
.card__line {
  opacity: 0;
  /* animation: LineFadeIn 0.6s 0.6s forwards ease-in; */
}
.card__image {
  opacity: 0;
  /* animation: ImageFadeIn 2s 1.3s forwards; */
}
.card__line-a {
  /* opacity: 0; */
  animation: LineFadeIn 0.6s 0.6s forwards ease-in;
}
.card__image-a {
  /* opacity: 0; */
  animation: ImageFadeIn 2s 1.3s forwards;
}
.card__title {
  margin-top: 0;
  /* font-weight: 800; */
  font-size: 1rem;
  font-family: "Open sans", sans-serif;
  letter-spacing: 0.01em;
}

.card__content {
  margin-top: -1rem;
  position: absolute;
  bottom: 10px;
  left: 15px;
  opacity: 0;
  /* animation: ContentFadeIn 0.6s 1.2s forwards; */
}
.card__content-a {
  animation: ContentFadeIn 0.6s 1.2s forwards;
}
.card__svg {
  position: absolute;
  left: 0;
  bottom: 0;
}
.card50svg {
  bottom: -4rem;
  left: 0;
}
.card100svg {
  bottom: -16rem;
  left: 0rem;
}

@keyframes LineFadeIn {
  0% {
    opacity: 0;
    d: path(
      "M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 "
    );
    stroke: #fff;
  }
  50% {
    opacity: 1;
    d: path(
      "M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300"
    );
    stroke: rgb(255, 255, 255);
  }
  100% {
    opacity: 1;
    d: path(
      "M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400"
    );
    stroke: rgb(255, 255, 255);
  }
}
@keyframes ContentFadeIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes ImageFadeIn {
  0% {
    transform: translate(-0.5rem, -0.5rem) scale(1.05);
    opacity: 0;
    filter: blur(2px);
  }
  50% {
    opacity: 1;
    filter: blur(2px);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

.show-more {
  padding: 0.5rem 5rem;
  border: none;
  border-radius: 1000px;
  background-color: rgb(210, 210, 210);
  color: rgb(117, 117, 117);
  font-family: "Roboto", sans-serif;
  transform: translateY(-1rem);
  cursor: pointer;
}
.widthit {
  width: 100%;
  height: auto;
}
.basket {
  display: flex;
  align-items: center;
  border-radius: 1000px;
  /* background-color: #aaa; */
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.175);
  padding: 0 3rem;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999911;
  cursor: pointer;
  border: none;
}
.badge {
  position: absolute;
  top: -0.3rem;
  right: -0.2rem;
  background-color: green;
  height: 1.8rem;
  width: 1.8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 1000px;
  font-family: "Poppins", sans-serif;
}
.basket img {
  height: 3rem;
  width: 3rem;
}
.basket span {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: rgb(0, 78, 29);
  transform: translateY(3px);
}

.modal-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s ease;
}
.modal-overlay {
  background-color: rgba(255, 255, 255, 0.382);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  /* transition: 0.5s ease; */
  /* transition: opacity 0.5s ease; */
  /* transition: opacity 0.5s ease transform 1s 0s ease; */
}
.hide-modal {
  transform: translateY(-100vh);

  transition: 0.5s ease;
}
.hide-overlay {
  transition: 0.5s ease;
  opacity: 0;
  transform: translateY(200vh);
  /* opacity: 0; */
}
.modal {
  /* height: 70vh; */
  position: relative;
  max-height: 90vh;
  width: 80vw;
  background-color: white;
  border-radius: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999999999999999;
}
.modal::-webkit-scrollbar {
  width: 0em;
  background-color: #e8e8e7;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #000000;
}
textarea::-webkit-scrollbar {
  width: 0em;
  /* background-color: #e8e8e7; */
}

textarea::-webkit-scrollbar-thumb {
  /* background-color: #000000; */
}

.basket-title {
  text-align: center;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: green;
}
.basket-sub-title {
  font-family: "Poppins", sans-serif;
  padding: 1rem;
  padding-left: 2rem;
  font-weight: bold;
  padding-bottom: 0;
}
.selected {
  padding: 1rem 2rem;

  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.fruit-in-basket {
  border: 1px solid green;
  font-family: "Open sans", sans-serif;
  font-size: 0.8rem;
  color: green;
  padding: 0.3rem 1rem;
  border-radius: 1000px;
}
.fruit-in-basket span {
  font-size: 0.6rem;
  padding-left: 0.3rem;
  color: rgb(116, 116, 116);
}

.basket-form {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.basket-form div {
  width: calc(50% - 2rem);
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: column;
}
.messege-lable {
  display: block;
  width: 100%;
  padding: 0.3rem 1rem;
}
textarea {
  width: 100%;
  margin: 1rem;
  margin-top: 0.3rem;
  padding: 0.4rem;
  border-radius: 1000px;
  outline: none;
  border: 1px solid rgba(97, 97, 97, 0);
  background-color: rgb(223, 223, 223);
  padding-left: 0.7rem;
  height: 2em;
  /* background-color: rgb(0, 81, 0); */
  caret-color: #00c610;
  /* caret: 20px; */
  color: rgb(0, 78, 29);
  font-family: "Roboto", sans-serif;
  resize: none;
  writing-mode: horizontal-tb !important;
}
input {
  font-family: "Roboto", sans-serif;
  padding: 0.4rem;
  border-radius: 1000px;
  background-color: rgb(223, 223, 223);

  border: 2px solid rgba(92, 92, 92, 0);
  margin-top: 0.3rem;
  /* background-color: rgb(0, 81, 0); */
  color: rgb(0, 78, 29);
  padding-left: 0.7rem;
  caret-color: #00c610;
  writing-mode: horizontal-tb !important;
}
input:focus {
  outline: none;
  border-bottom: #00c610 2px solid;
}
textarea:focus {
  outline: none;
  border-bottom: #00c610 2px solid;
}
/* border: 2px solid rgba(0, 255, 42, 0.598); */

.send-button {
  width: 100%;
  margin: 1rem;
  padding: 0.5rem;
  border: none;
  background-color: #00533b00;
  background-color: green;
  color: white;
  font-family: "Poppins", sans-serif;
  border-radius: 10000px;
  cursor: pointer;
}
label {
  font-family: "Open sans", sans-serif;
  font-size: 0.8rem;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.nodelay {
  animation-delay: 0s;
}
@media (max-width: 1200px) {
  .card50svg {
    bottom: -2rem;
  }
  .card100svg {
    bottom: -10rem;
  }
}
@media (max-width: 1000px) {
  .card50svg {
    bottom: -2rem;
  }
  .card100svg {
    bottom: -8rem;
  }
}
@media (max-width: 875px) {
  .card100svg {
    bottom: -7rem;
  }
}
@media (max-width: 800px) {
  .card {
    cursor: pointer;
    position: relative;
    background: #ddd;
    width: calc(70vw);
    height: 30vw;
    border-radius: 30px;
    padding: 2rem;
    color: #aaa;
    /* box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2); */
    overflow: hidden;
  }
  .card__svg {
    bottom: -2.8rem;
  }
  .ssimg {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .card__svg {
    bottom: -2rem;
  }
}
@media (max-width: 400px) {
  .card {
    cursor: pointer;
    position: relative;
    background: #ddd;
    width: calc(70vw);
    height: 30vw;
    border-radius: 30px;
    padding: 2rem;
    color: #aaa;
    /* box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2); */
    overflow: hidden;
  }
  .card__svg {
    bottom: -1rem;
  }
  .ssimg {
    height: auto;
    width: 100%;
  }
  .show-more {
    padding: 0.5rem 6rem;
    width: 80vw;
  }
}

.f-headline {
  font-family: "Philosopher", serif;
  font-weight: bolder;
  font-size: 2rem;
  color: rgb(1, 104, 1);
  margin-top: 2rem;
}
.f-button {
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 91, 35, 0.779);
  border: 3px rgba(0, 74, 28, 0.779) solid;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 1.2rem;
  border-radius: 1000px;
  cursor: pointer;
}
#psps {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.2rem;
  flex-wrap: wrap;
  font-family: "Open sans", sans-serif;
  font-size: 0.8rem;
  /* background-color: #aaa; */
}
.tt {
  padding: 0.3rem 1rem;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 1000px;
  cursor: pointer;
}
.e-n-h {
  color: green;
  border: 1px green solid;
}

@media (max-width: 800px) {
  .basket-form div {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .f-button {
    width: calc(100vw - 8rem);
  }
}
