.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  margin-top: 4rem;
}
.contact h2 {
  font-size: 1.8rem;

  font-family: "headerFont";
  margin-bottom: 3rem;
  width: auto;
  text-align: center;
  color: rgb(106, 106, 106);
}

.contact-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.show-mob {
  display: none;
}
.details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.numbers a {
  /* padding: 0.8rem 1rem; */
  width: 100%;
  min-width: 18rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: rgba(0, 81, 53, 0.893);
  gap: 1rem;
  border-radius: 1000px;
  /* border-top-right-radius: 1000px; */
  background-color: rgb(194, 194, 194);
  display: flex;
  align-items: center;
  /* padding: 1rem; */
  /* border: 2px solid rgb(19, 191, 0); */
  text-decoration: none;
}
.numbers a img {
  /* border-radius: 1000px; */
  height: 3rem;
  /* background-color: rgba(0, 81, 53, 0.893); */
  /* padding: 0.6rem; */
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.links a {
  width: 5rem;
  height: 5rem;
  background-color: rgba(0, 81, 53, 0.893);
  border-radius: 1000px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border: 2px solid rgb(19, 191, 0);

  /* color: green; */
}
.links a img {
  width: 1.7rem;
}

.avocado-s,
.mango-s,
.pineapples-s,
.banana-s,
.pf-ca-s,
.c-g-s,
.f-v-s {
  display: block;
  width: calc(100vw);
  text-align: center;
  padding: 1rem 0;
  background: rgba(0, 0, 0, 0.767);
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 800px) {
  .contact {
    padding: 4rem;
    padding-top: 6rem;
    width: calc(100vw - 8rem);
    overflow: hidden;
    /* background-csolor: aqua; */
    margin: auto;
  }
  .details {
    flex-direction: column;
  }
  .links {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .contact-details {
    flex-direction: column-reverse;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }
  .links a {
    width: 3.6rem;
    height: 3.6rem;
  }
  .tree img {
    width: 100vw;
  }
  .show-mob {
    display: block;
  }
  .numbers {
    width: 50%;
  }
  .numbers div {
    width: 100%;
  }
  .hide-mob {
    display: none;
  }
}
.tits {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  min-width: 18rem;
  margin: 2rem;
}
.tits a img {
  width: 3rem;
}
.db {
  padding: 0.8rem 2rem;
  background-color: rgba(0, 81, 53, 0.893);
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
  font-size: 0.8rem;
  width: 60%;
  text-align: center;
  text-decoration: none;
  border-radius: 1000px;
}

.cp {
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  text-align: center;
}
.cp a {
  color: green;
  /* text-align: center; */
}
