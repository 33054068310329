@import "./css/home.css";
@import "./css/fruits.css";
@import "./css/nav.css";
@import "./css/about.css";
@import "./css/contact.css";
* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
  /* position: relative; */
}
body {
  overflow-x: hidden;
  background-color: #e8e8e7;
}
body::-webkit-scrollbar {
  width: 0em;
  background-color: #e8e8e7;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}
