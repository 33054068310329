.nav-bar {
  position: fixed;
  top: 0;
  background-color: #e8e8e7;
  left: 0;
  padding: 0.6rem;
  width: calc(100% - 1.21rem);
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  transition: 0.4s ease;
  align-items: center;
}
.shrink {
  transition: 1s ease;
}
.shrink img {
  transition: 0.4s ease;
  height: 2rem;
}
.nav-logo {
  height: 2.6rem;
  transition: 0.4s ease;
}
.nav-list {
  display: flex;
}
.nav-item {
  text-decoration: none;
  list-style: none;
  font-size: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family: "Poppins", sans-serif;
  color: rgb(94, 94, 94);
  cursor: pointer;
}
.active {
  color: rgb(0, 150, 70);
  font-weight: bold;
}

.cn-wrapper {
  font-size: 1em;
  width: 26em;
  height: 26em;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  bottom: -13em;
  left: 50%;
  border-radius: 50%;
  margin-left: -13em;
  transform: scale(0.1);
  pointer-events: none;
  transition: all 0.3s ease;
}
.cn-wrapper.opened-nav {
  border-radius: 50%;
  pointer-events: auto;
  transform: scale(1);
}

.cn-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 2;
}
.cn-overlay.on-overlay {
  visibility: visible;
  opacity: 0;
  z-index: -200;
}

.cn-button {
  border: none;
  background: none;
  color: #e0e0e0;
  text-align: center;
  font-size: 1.8em;
  padding-bottom: 1.2em;
  height: 5em;
  width: 5em;
  background-color: green;
  position: fixed;
  left: 50%;
  margin-left: -2.5em;
  bottom: -2.5em;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  outline: none;
}

.cn-wrapper li {
  position: absolute;
  font-size: 1.5em;
  width: 10em;
  height: 10em;
  transform-origin: 100% 100%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  /* transform: rotate(2110deg); */
  margin-top: -1.3em;
  margin-left: -10em;
  transition: border 0.3s ease;
}
.cn-wrapper li a {
  display: block;
  font-size: 1.18em;
  height: 14.5em;
  width: 14.5em;
  position: absolute;
  bottom: -7.25em;
  right: -7.25em;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
  padding-top: 1.8em;
  text-align: center;
  transform: skew(-50deg) rotate(-70deg) scale(1);
  transition: opacity 0.3s, color 0.3s, background 0.3s;
}
.cn-wrapper li a span {
  font-size: 1.1em;
  opacity: 0.7;
}
.cn-wrapper li:first-child {
  transform: rotate(0deg) skew(50deg);
}
.cn-wrapper li:nth-child(2) {
  transform: rotate(47deg) skew(50deg);
}
.cn-wrapper li:nth-child(3) {
  transform: rotate(93deg) skew(50deg);
}
.cn-wrapper li:nth-child(4) {
  transform: rotate(140deg) skew(50deg);
}
.cn-wrapper li:nth-child(5) {
  transform: rotate(180deg) skew(50deg);
  display: none;
}
.cn-wrapper li:nth-child(odd) a {
  background-color: #004628e0;
}
.cn-wrapper li:nth-child(even) a {
  background-color: rgb(0, 162, 57);
}
.cn-wrapper li.active a {
  background-color: #aee8ff;
}
/* .cn-wrapper li:not(.active) a:hover,
.cn-wrapper li:not(.active) a:active {
  background-color: rgb(0, 162, 57);
}
.cn-wrapper li:not(.active) a:focus {
  background-color: rgb(45, 45, 45);
  position: fixed;
} */
.wrap-mob-ss {
  display: none;
}
/* for a central angle x, the list items must be skewed by 90-x degrees
*in our case x=40deg so skew angle is 50deg
*items should be rotated by x, minus (sum of angles - 180)2s (for this demo) */
@media (max-width: 800px) {
  .wrap-mob-ss {
    display: block;
  }
  #nav-bar {
    position: relative;
    background: none;
    margin: 0rem 0rem;
  }
  .nav-list {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .cn-wrapper {
    font-size: 0.68em;
  }
  .cn-button {
    font-size: 1em;
  }
  .cn-wrapper li {
    font-size: 1.52em;
  }
}
