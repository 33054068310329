@font-face {
  font-family: "headerFont";
  src: url("../fonts/Mollen Personal Use-BoldNarrow.otf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Roboto&display=swap");
.hero-img {
  width: 100%;
}
.pre {
  width: 100%;
  height: 100%;
  background-color: #e8e8e7;
  position: fixed;
  display: flex;
  z-index: 99999999999999999999;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: 1s ease;
}
.gif {
  /* width: 50%; */
  height: 12rem;
  opacity: 0.7;
  transition: 1s ease;
}
.move-left {
  transition: 1s ease;
  transition-delay: 1s;
  transform: translateX(-100vw);
}
.go-away {
  transition: 1s ease;
  opacity: 0;
}
.hero-container {
  position: relative;
  height: 100vh;
}

.hero-text-container {
  position: absolute;
  top: 60%;
  left: 2rem;

  animation: header 1s forwards;
  animation-delay: 8.5s;
}
@keyframes header {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-60%);
  }
}
.hero-text-container h1 {
  display: inline-flex;
  position: relative;
  font-family: "headerFont";
  font-weight: 400;
  font-size: 3.5vw;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  /* text-shadow: 0 0 60px rgba(0, 0, 0, 0.2); */
}
.hero-text-container h1::after {
  position: absolute;
  top: 0;
  bottom: -10px;
  left: 0;
  display: inline-block;
  overflow: hidden;
  width: 0%;
  white-space: pre;
  content: attr(data-text);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.5px rgba(0, 101, 44, 0.7);
  will-change: width;
  animation: grow 4s cubic-bezier(0.86, 0, 0.07, 1) 0.3s forwards;
  animation-delay: 5.3s;
}
.header-span {
  display: inline-block;
  -webkit-text-stroke: 1px rgba(0, 130, 69, 0.2);
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0;
  position: relative;
}
.header-span::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  width: 0%;
  white-space: pre;
  content: attr(data-text);
  -webkit-text-stroke: rgba(255, 255, 255, 0);
  will-change: width;
  animation: grow 4s cubic-bezier(0.86, 0, 0.07, 1) 2s forwards;
  animation-delay: 7s;
  background: linear-gradient(
    to bottom,
    rgba(0, 128, 57, 0.85) 0%,
    rgba(0, 59, 23, 0.779) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text {
  /* animation-delay:4s ; */
  /* max-width: 45%; */
  font-size: 1rem;
  color: rgb(127, 127, 127);
  opacity: 0;
  font-family: "Roboto", sans-serif;
  /* font-weight: bolder; */
  /* font-size: 1.1vw; */
  animation: show 1s forwards;
  animation-delay: 8.5s;
  padding-left: 0.3rem;
  height: auto;
  transform: translateX(0.5rem);
}
@keyframes grow {
  0% {
    width: 0%;
  }
  50%,
  100% {
    width: 100%;
  }
}
.action-button {
  transform: translateY(0.5rem);
  animation: showBot 1s forwards;
  animation-delay: 8.5s;
  /* animation-delay: 4.2s; */
  padding: 0.8rem 2.5rem;
  font-family: "Open Sans", sans-serif;
  margin: 2rem;
  margin-left: 0;
  border: 2px #e8e8e7 solid;

  opacity: 0;
  font-size: 0.9rem;
  border-radius: 1000px;
  background: linear-gradient(
    to bottom,
    rgba(0, 151, 68, 0.85),
    rgba(0, 103, 39, 0.779)
  );
  color: white;
  text-decoration: none;
  transition: 0.3s ease;
  cursor: pointer;
}
.action-button:hover {
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 91, 35, 0.779);
  border: 2px rgba(0, 74, 28, 0.779) solid;
}

@keyframes show {
  0% {
    transform: translateX(0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes headerx {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-25%);
  }
}
@keyframes showBot {
  0% {
    transform: translateY(0.5rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hero-mob-img {
  display: none;
}
@media (max-width: 1200px) {
  .hero-container {
    /* margin-top: 2rem;he */
    height: auto;
    /* min-height: 100vh; */
  }
  .action-button {
    background: rgba(0, 0, 0, 0);
    color: rgba(0, 91, 35, 0.779);
    border: 3px rgba(0, 74, 28, 0.779) solid;
    font-weight: bolder;
    font-family: "Poppins", sans-serif;
    padding: 0.5rem 1.2rem;
  }

  .hero-text-container {
    display: block;
    /* position: relative; */
    width: calc(100% - 4rem);
    margin-top: 4rem;
    top: 40%;
    transform: translateY(60%);
  }
}
@media (max-width: 800px) {
  .action-button {
    background: rgba(0, 0, 0, 0);
    color: rgba(0, 91, 35, 0.779);
    border: 3px rgba(0, 74, 28, 0.779) solid;
    font-weight: bolder;
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
    padding: 0.3rem 0.8rem;
  }
  .hero-container {
    /* margin-top: 2rem;he */
    min-height: 80vh;
    height: auto;
    /* min-height: 100vh; */
  }
  .hero-img {
    display: none;
  }
  .hero-mob-img {
    display: block;
    width: 100vw;
    margin-top: 1rem;
  }
  .hero-text-container h1 {
    font-size: 6.2vw;
    /* text-shadow: 0 0 60px rgba(0, 0, 0, 0.2); */
  }
  .hero-text-container h1::after {
    -webkit-text-stroke: 1.2px rgba(0, 101, 44, 0.7);
  }
  .text {
    /* max-width: 45%; */
    /* font-size: 1rem; */
    /* font-weight: bolder; */
    padding: 0rem;
    width: 70%;
    padding-top: 0.6rem;
    font-size: 3.5vw;
  }
  .hero-text-container {
    display: block;
    position: relative;
    width: calc(100% - 4rem);
    margin-top: 4rem;
    animation: headerx 1s forwards;
    animation-delay: 8.5s;
    transform: translateY(10%);
  }
}

@media (max-width: 400px) {
  .hero-text-container h1 {
    font-size: 5.8vw;
    /* text-shadow: 0 0 60px rgba(0, 0, 0, 0.2); */
  }
}
