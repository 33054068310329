/* FontAwesome Icons */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://kit.fontawesome.com/e08c9c92af.css");

/* Google Fonts */

.about-container {
  position: relative;
}
.about-svg-mob {
  display: none;
}
.about-container h2 {
  font-size: 2.6rem;
  padding-top: 5rem;
  padding-left: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  width: 60%;
  color: white;
}
.about-container p {
  font-family: "Roboto", sans-serif;
  color: rgb(233, 255, 225);
  width: 50%;
  font-weight: lighter;
  padding-left: 2.3rem;
}
.about-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 4px);
  transform: translate(-2px, -5rem);
  z-index: -100;
}
.cool-count {
  background-color: #f9f7f2;
  width: 70%;
  border-radius: 2rem;
  margin: auto;
  padding-bottom: 2rem;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.184);
  margin-top: 7rem;
}
.vision {
  /* margin-top: 9rem; */
  width: 55%;
  padding-left: 3rem;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: rgb(0, 72, 29);
}

.wrapper {
  /* position: absolute; */
  margin: auto;
  padding-top: 2rem;
  display: flex;
  margin-right: 5rem;
  margin-left: 5rem;
  justify-content: space-around;
  gap: 20px;
}
.containerx {
  width: 28vmin;
  height: 28vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em 0;
  position: relative;
  font-size: 16px;
  border-radius: 1.5em;
  background-color: #0071281b;
  border-bottom: 10px solid #008d4b;
}
/* .svga {
  color: #18f98f;
  font-size: 2.5em;
  text-align: center;
} */
span.num {
  color: #008d4b;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
  font-family: "Poppins", sans-serif;
}
span.textx {
  color: #008d4b;
  font-size: 0.8rem;
  padding-top: 1rem;
  text-align: center;
  pad: 0.7em 0;
  font-weight: 400;
  line-height: 0;
  font-family: "Poppins", sans-serif;
}
.about-svg-mob1,
.about-svg-mob2 {
  display: none;
}
@media (max-width: 1150px) {
  .wrapper {
    width: calc(100% - 2rem);

    margin: auto;
    padding-top: 2rem;
    gap: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .containerx {
    /* height: 26vmin; */
    width: calc(50vw - 13rem);
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    width: calc(100% - 2rem);

    margin-right: 0rem;
    margin-left: 0rem;
    gap: 30px;
    flex-wrap: wrap;
    /* background-color: #008d4b; */
    padding: 1rem;
    /* padding-top: 5rem; */
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .containerx {
    width: 100vw;
    height: 30vmin;
    font-size: 14px;
  }
  .about-svg {
    display: none;
  }
  .vision {
    /* margin-top: 5rem; */
    width: 80%;
  }
}
@media screen and (max-width: 950px) {
  .about-svg-mob1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -2222;
  }
  .about-svg {
    display: none;
  }
  .about-svg-mob2 {
    display: none;
  }
  .about-container h2 {
    padding-top: 8rem;
  }
}
@media screen and (max-width: 650px) {
  .about-container h2 {
    font-size: 1.8rem;
    width: 80%;
    padding-top: 8rem;
  }
  .about-container p {
    width: 80%;
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 480px) {
  .about-svg-mob1 {
    display: none;
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: -111;
  }
  .about-svg-mob2 {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -2222;
  }
  .about-container h2 {
    font-size: 1.6rem;
    width: 80%;
    padding-top: 7rem;
  }
  .about-container p {
    width: 80%;
    font-size: 1rem;
  }
  .wrapper {
    gap: 15px;
  }
  .containerx {
    width: 100%;
    height: 25vmin;
    font-size: 8px;
  }
}
.v-s {
  /* font-family: ""; */

  font-family: "Philosopher", serif;
  font-weight: bolder;
  font-size: 2rem;
  color: rgb(1, 104, 1);
  margin-top: 7rem;
  padding: 2rem;
}

@media screen and (max-width: 380px) {
  .about-container h2 {
    padding-top: 3rem;
  }
}
